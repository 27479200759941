<h2 mat-dialog-title class="container-width overflow-hidden">
  {{ 'UI.GENERAL_CORRESPONDENCE.NEW_GENERAL_CORRESPONDENCE' | translate }}
</h2>

<form [formGroup]="formGroup">
  <div mat-dialog-content>
    <div class="container">
      <mat-form-field>
        <mat-label>{{ 'UI.SUBJECT' | translate }}</mat-label>
        <input matInput formControlName="subject" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'UI.MESSAGE' | translate }}</mat-label>
        <textarea formControlName="body" type="text" rows="3" matInput required></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'UI.COUNTRY' | translate }}</mat-label>
        <mat-select required formControlName="receivingCountry" #selectedCountry (selectionChange)="onCountrySelection(selectedCountry.value)">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="agencies">
        <mat-label> {{ 'UI.RECEIVING_AGENCY' | translate }}</mat-label>
        <mat-select formControlName="receivingAgency" #selectedAgency (selectionChange)="onAgencySelection(selectedAgency.value)">
          <mat-option *ngFor="let agency of agencies" [value]="agency">
            {{ agency.formattedName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="departments">
        <mat-label> {{ 'UI.RECEIVING_DEPARTMENT' | translate }} </mat-label>
        <mat-select formControlName="receivingDepartment">
          <mat-option *ngFor="let department of departments" [value]="department">
            {{ department.formattedName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-file-upload (multipleFilesOutput)="fileOutputHandler($event)" [multiple]="true" [multipleFilesInput]="getAttachments()"> </app-file-upload>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <button mat-button color="primary" cdkFocusInitial type="button" (click)="onSubmit()">
      <mat-icon>send</mat-icon> {{ 'ACTIONS.SEND' | translate }}
    </button>
    <button mat-button mat-dialog-close color="primary"><mat-icon>cancel</mat-icon> {{ 'ACTIONS.CANCEL' | translate }}</button>
  </div>
</form>
