<div class="mb-5">
  <mat-card>
    <mat-card-content>
      <mat-list role="list">
        <mat-list-item role="listitem">
          <b>{{ ('UI.SUBJECT' | translate) + ': ' }} </b> {{ generalCorrespondence.subject }}
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>{{ ('UI.BODY' | translate) + ': ' }} </b> {{ generalCorrespondence.body }}
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>{{ ('UI.SENT_BY' | translate) + ': ' }} </b> {{ generalCorrespondence.sentByGroup?.formattedName }}
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>{{ ('UI.RECEIVED_BY' | translate) + ': ' }} </b> {{ generalCorrespondence.receivingGroup?.formattedName }}
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>{{ ('UI.RECEIVING_COUNTRY' | translate) + ': ' }} </b> {{ generalCorrespondence.receivingCountryCode }}
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>{{ ('UI.CREATED' | translate) + ': ' }} </b> {{ generalCorrespondence.createdAt | date: 'yyyy-MM-dd hh:mm' }}
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>{{ ('UI.GENERAL_CORRESPONDENCE.LAST_RESPONSE' | translate) + ': ' }} </b>
          {{ generalCorrespondence.lastResponseAt | date: 'yyyy-MM-dd hh:mm' }}
        </mat-list-item>
      </mat-list>
      <mat-list role="link">
        <mat-list-item role="listitem" *ngFor="let attachment of generalCorrespondence.attachments">
          <button mat-stroked-button color="primary" class="file-btn" (click)="onDownloadFile(attachment)">
            <mat-icon>attach_file</mat-icon> {{ attachment.fileName }} {{ attachment.sizeInKiloBytes }} Kb
          </button>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>

<div class="mb-2 d-flex">
  <button mat-flat-button color="primary" class="me-2" (click)="onReply()" [disabled]="generalCorrespondence.closed">
    <mat-icon>reply</mat-icon> {{ 'UI.REPLY' | translate }}
  </button>
  <button mat-stroked-button color="warn" (click)="onClose()" [disabled]="generalCorrespondence.closed" *ngIf="canClose()">
    {{ 'UI.CLOSE' | translate }}
  </button>
</div>

<div class="p-3 h-50 overflow-auto shadow" style="background-color: #e6e6e6">
  <div class="mb-3" *ngFor="let reply of generalCorrespondenceReplies">
    <div class="w-50 p-3" [class.other-user-bg-color]="!isReplyFromUser(reply)" [class.current-user-bg-color]="isReplyFromUser(reply)">
      {{ getReplyAuthor(reply) }}
      <br />
      {{ 'Sent on: ' + (reply.createdAt | date: 'yyyy-MM-dd hh:mm') }}
      <mat-card>
        <mat-card-content>
          {{ reply.body }}
        </mat-card-content>
      </mat-card>
      <mat-list role="link">
        <mat-list-item role="listitem" class="px-0" *ngFor="let attachment of reply.attachments">
          <button mat-stroked-button color="primary" class="file-btn" (click)="onDownloadFile(attachment)">
            <mat-icon>attach_file</mat-icon> {{ attachment.fileName }} {{ attachment.sizeInKiloBytes }} Kb
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>

<div style="background-color: #c6c6c6">
  <mat-paginator
    [hidePageSize]="false"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="collatePaginateQueryDto.pageIndex"
    [pageSize]="collatePaginateQueryDto.pageSize"
    [length]="getTotalItems"
    (page)="selectedPage($event)"
  ></mat-paginator>
</div>
