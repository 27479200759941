<mat-sidenav-container *ngIf="permissions$ | async as permissions" class="app-sidenav-container" [class.optional-theme]="isOptionalTheme">
  <mat-sidenav #sidenav class="app-sidenav mat-elevation-z10" mode="side">
    <mat-toolbar color="primary"> Menu</mat-toolbar>
    <mat-nav-list *ngIf="userRoles$ | async as userRoles">
      <mat-list-item
        *ngIf="permissions.includes(permissionTypes.RegularUser) && permissions.includes(permissionTypes.CaseStatusDashboardView)"
        routerLink="/cases"
      >
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">dashboard</mat-icon>
          <a class="text">{{ 'PAGE_NAMES.CASE_STATUS_DASHBOARD' | translate }}</a>
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="permissions.includes(permissionTypes.ReturnScheduleCalendarView)" routerLink="/cases/return-schedule-calendar">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">event_repeat</mat-icon>
          <a class="text">{{ 'PAGE_NAMES.RETURN_SCHEDULE_CALENDAR' | translate }}</a>
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="userRoles.includes(roleTypes.AdminUser)" routerLink="/system-administration/group-management">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">groups</mat-icon>
          <a class="text">{{ 'PAGE_NAMES.GROUP_ADMINISTRATION' | translate }}</a>
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="userRoles.includes(roleTypes.AdminUser)" routerLink="/system-administration/admin-management">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">manage_account</mat-icon>
          <a class="text">{{ 'PAGE_NAMES.USER_ADMINISTRATION' | translate }}</a>
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="userRoles.includes(roleTypes.PrimaryUser)" routerLink="/system-administration/user-management">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">manage_account</mat-icon>
          <a class="text">{{ 'PAGE_NAMES.USER_ADMINISTRATION' | translate }}</a>
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="permissions.includes(permissionTypes.MainAgencyPrimary)" routerLink="/system-administration/calendar-management">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">calendar_month</mat-icon>
          {{ 'PAGE_NAMES.CALENDAR_ADMINISTRATION' | translate }}
        </a>
      </mat-list-item>

      <mat-list-item
        *ngIf="permissions.includes(permissionTypes.RegularUser) && permissions.includes(permissionTypes.StatisticsView)"
        routerLink="/statistics/"
      >
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">insert_chart</mat-icon>
          {{ 'PAGE_NAMES.STATISTICS_AND_REPORTS' | translate }}
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="userRoles.includes(roleTypes.AdminUser)" routerLink="/language-administration/">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">language</mat-icon>
          {{ 'PAGE_NAMES.LANGUAGE_ADMINISTRATION' | translate }}
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="userRoles.includes(roleTypes.WorkshopAdmin)" routerLink="/workshop-administration/">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">asd</mat-icon>
          {{ 'Workshop.Header' | translate }}
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="permissions.includes(roleTypes.AdminUser)" routerLink="/workflow/">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">list_alt</mat-icon>
          {{ 'UI.WORKFLOW.WORKFLOW_ADMINISTRATION' | translate }}
        </a>
      </mat-list-item>

      <mat-list-item routerLink="/general-correspondence/">
        <a class="fs-6" matListItemTitle>
          <mat-icon class="material-icons-outlined">mail</mat-icon>
          {{ 'UI.GENERAL_CORRESPONDENCE.HEADER' | translate }}
        </a>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="h-100">
    <app-toolbar (toggleSidenav)="sidenav.toggle()" (changeLanguage)="onChangedLanguage($event)" (toggleTheme)="toggleTheme()"></app-toolbar>
    <app-error></app-error>
    <div class="wrapper">
      <app-loader></app-loader>
      <router-outlet></router-outlet>
    </div>
    <!-- <app-footer></app-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>
