import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GeneralCorrespondenceStateService } from 'src/app/general-correspondence/general-correspondence-state.service';
import { CreateGeneralCorrespondenceDto, GeneralCorrespondenceDto, GeneralCorrespondenceDtoPaginatedList } from 'swagger';
import { CreateGeneralCorrespondenceDialogComponent } from '../../dialogs/create-general-correspondence-dialog/create-general-correspondence-dialog.component';
import { Observable, concatMap, first, of } from 'rxjs';
import { GeneralCorrespondenceService } from '@shared/services';
import { CollatePaginateQueryDto } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';

const COLUMN_NAMES: string[] = ['subject', 'createdAt', 'sentByGroup', 'receivingCountry', 'receivingGroup', 'lastResponseAt'];

@Component({
  selector: 'app-general-correspondence-inbox',
  templateUrl: './general-correspondence-inbox.component.html',
  styleUrls: ['./general-correspondence-inbox.component.scss', '../../../../../assets/bootstrap-utilities.min.css'],
})
export class GeneralCorrespondenceInboxComponent implements OnInit {
  private requestedData: GeneralCorrespondenceDtoPaginatedList | undefined = undefined;

  protected dataSource: MatTableDataSource<GeneralCorrespondenceDto>;
  protected ready = false;
  protected displayedColumns: string[] = COLUMN_NAMES;
  protected tabIndex = 0;
  protected searchValue = '';
  protected collatePaginateQueryDto: CollatePaginateQueryDto = {
    pageSize: 20,
    pageIndex: 0,
    sortByDescending: false,
    sortBy: ['createdAt'],
    filters: ['closed=false'],
  };

  protected allowedProperties: { [key: string]: string } = {};

  public constructor(
    private _router: Router,
    private _generalCorrespondenceStateService: GeneralCorrespondenceStateService,
    private _dialog: MatDialog,
    private generalCorrespondenceService: GeneralCorrespondenceService,
    private _translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.loadData();
    this.ready = true;
  }

  private setAllowProperties() {
    const prefixKey = 'DTO.GENERALCORRESPONDENCEDTO';
    this.requestedData?.filterableProperties?.forEach((e) => {
      const translatedValue = `${prefixKey}.${e.toUpperCase()}`;
      this.allowedProperties[e] = this._translateService.instant(translatedValue);
    });
  }

  protected onRowClicked(row: GeneralCorrespondenceDto): void {
    this.goToMessageThreadPage(row);
  }

  public setSearchValue(event: any): void {
    this.searchValue = (event.target as HTMLInputElement).value;
  }

  protected onSearch() {
    this.collatePaginateQueryDto.searchBy = this.searchValue;
    this.loadData();
  }

  protected onSortByDescendingToggle() {
    this.collatePaginateQueryDto.sortByDescending = !this.collatePaginateQueryDto.sortByDescending;
    this.loadData();
  }

  protected get getTotalItems() {
    return this.requestedData?.totalItems;
  }

  protected get getPageSize() {
    return this.requestedData?.items;
  }

  private loadData(addFunction?: () => void) {
    this.getGeneralCorresspondences$()
      .pipe(first())
      .subscribe((e: GeneralCorrespondenceDtoPaginatedList) => {
        if (e) {
          this.requestedData = e;
          this.dataSource = new MatTableDataSource<GeneralCorrespondenceDto>(e.items);
          this.setAllowProperties();
        }
      })
      .add(addFunction);
  }

  protected onCreateGeneralCorrespondenceDialog(): void {
    this._dialog
      .open(CreateGeneralCorrespondenceDialogComponent)
      .afterClosed()
      .pipe(
        first(),
        concatMap((e: CreateGeneralCorrespondenceDto) => {
          if (!e) {
            return of(null);
          }

          return this.addGeneralCorrespondence$(e);
        }),
      )
      .subscribe((e: CreateGeneralCorrespondenceDto | null) => {
        if (e) {
          console.log('finished creating correspondence');
        }
      })
      .add(() => {
        this.loadData();
      });
  }

  protected selectedPage(pageEvent: PageEvent) {
    this.collatePaginateQueryDto.pageIndex = pageEvent.pageIndex;
    this.collatePaginateQueryDto.pageSize = pageEvent.pageSize;
    this.loadData();
  }

  protected goToMessageThreadPage(data: GeneralCorrespondenceDto): void {
    const url = `/general-correspondence/thread/${data.id}`;
    this._generalCorrespondenceStateService.SignalGeneralCorrespondence(data);
    this._router.navigateByUrl(url);
  }

  protected filterClosedMessages(closed: boolean, tabIndex: number) {
    const closedString = 'closed=';
    const filter = closedString + String(closed);
    this.collatePaginateQueryDto.filters = this.collatePaginateQueryDto.filters?.filter((e) => !e.startsWith(closedString));
    this.collatePaginateQueryDto.filters?.push(filter);
    this.loadData(() => {
      this.tabIndex = tabIndex;
    });
  }

  protected onSelectedSortBy(property: string) {
    this.collatePaginateQueryDto.sortBy = [property];
    this.loadData();
  }

  private getGeneralCorresspondences$(): Observable<GeneralCorrespondenceDtoPaginatedList> {
    return this.generalCorrespondenceService.getGeneralCorrespondences(this.collatePaginateQueryDto);
  }

  private addGeneralCorrespondence$(body: CreateGeneralCorrespondenceDto): Observable<GeneralCorrespondenceDto> {
    return this.generalCorrespondenceService.addGeneralCorrespondence(body);
  }
}
