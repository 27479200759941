<div class="notification-box" [ngClass]="{ 'notification-not-read': !notification.isRead }">
  <div style="padding-bottom: 0.5rem">
    <span [ngClass]="'notification-type ' + getNotificationTypeClass(notification.type)">
      {{ 'NotificationType.' + notification.type | translate }}
    </span>
    <div class="right delete-icon">
      <button mat-button color="warn" (click)="$event.stopPropagation(); deleteNotification(notification.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <p *ngIf="notification.notificationCategory === NoticationCategory.RegularUser">
    {{ 'NOTIFICATIONS.CASE_REFERENCE_NUMBER' | translate }}: {{ notification.reference }}
  </p>
  <p *ngIf="notification.type !== NotificationTypes.CaseAnonymizationReminder">
    {{ 'CASE_ATTRIBUTES.CREATED' | translate }}: {{ notification.created | date: 'yyyy-MM-dd HH:mm z' }}
  </p>
  <p *ngIf="notification.type === NotificationTypes.CaseAnonymizationReminder && notification.notificationCategory == NoticationCategory.RegularUser">
    {{ 'CASE_ATTRIBUTES.DAYS_LEFT' | translate }}: {{ notification.daysLeftForRemoval }}
  </p>

  <div class="flex center" style="justify-content: space-between">
    <button mat-stroked-button color="primary" *ngIf="!notification.isRead" (click)="$event.stopPropagation(); setRead(notification, true)">
      <mat-icon>mark_email_read</mat-icon> {{ 'NOTIFICATIONS.READ' | translate }}
    </button>
    <button mat-stroked-button color="primary" *ngIf="notification.isRead" (click)="$event.stopPropagation(); setRead(notification, false)">
      <mat-icon>mark_email_unread</mat-icon> {{ 'NOTIFICATIONS.UNREAD' | translate }}
    </button>
    <button
      *ngIf="notification.notificationCategory === NoticationCategory.RegularUser && !isGeneralCorrespondenceType()"
      mat-stroked-button
      color="primary"
      (click)="goToCase(notification)"
    >
      <mat-icon>arrow_forward</mat-icon> {{ 'NOTIFICATIONS.GO_TO_CASE' | translate }}
    </button>

    <button *ngIf="isGeneralCorrespondenceType()" mat-stroked-button color="primary" (click)="goToGeneralCorrespondence(notification)">
      <mat-icon>arrow_forward</mat-icon> {{ 'UI.OPEN' | translate }}
    </button>
  </div>
</div>
