import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CaseService } from './case.service';
import { first } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  public constructor(
    private readonly router: Router,
    private caseService: CaseService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  public goToCase(caseId: string) {
    if (caseId != null) {
      this.caseService
        .getCase(caseId)
        .pipe(first())
        .subscribe((caseData) => {
          this.router.navigate(['/cases/manage-case/' + caseId], {
            queryParams: {
              type: caseData.caseType,
              country: caseData.receivingCountryCode,
            },
          });
        });
    } else {
      this.notificationService.showError(this.translateService.instant('ERRORS.CASE_NOT_FOUND'), '');
    }
  }

  public goToGeneralCorrespondenceThreadView(generalCorrespondenceId: string): void {
    const url = `/general-correspondence/thread/${generalCorrespondenceId}`;
    this.router.navigateByUrl(url).then(() => {
      window.location.reload();
    });
  }
}
