<h1>{{ 'UI.GENERAL_CORRESPONDENCE.HEADER' | translate }}</h1>

<div class="p-3 text-center align-middle shadow-sm mt-4 mb-5" style="background-color: #ffc107">
  <span> {{ 'UI.GENERAL_CORRESPONDENCE.NOTE' | translate }}</span>
</div>

<div class="mb-1 d-flex flex-row-reverse align-items-center py-2">
  <div class="ms-3">
    <button mat-raised-button color="primary" (click)="onCreateGeneralCorrespondenceDialog()">
      <mat-icon>add</mat-icon> {{ 'UI.GENERAL_CORRESPONDENCE.NEW_GENERAL_CORRESPONDENCE' | translate }}
    </button>
  </div>
</div>

<div class="mb-5 d-flex align-items-center shadow py-2 px-3 filter-bar">
  <div class="me-auto d-flex align-items-center">
    <mat-form-field appearance="standard" class="me-3" style="width: 18em">
      <mat-label> {{ 'UI.SEARCH' | translate }} </mat-label>
      <input (keyup)="setSearchValue($event)" (keyup.enter)="onSearch()" matInput placeholder="{{ 'ACTIONS.SEARCH' | translate }}" />
    </mat-form-field>
    <button
      [matTooltip]="'UI.GENERAL_CORRESPONDENCE.TOOLTIPS.SEARCH' | translate"
      mat-raised-button
      color="primary"
      class="me-3"
      (click)="onSearch()"
    >
      <mat-icon>search</mat-icon> Search
    </button>
  </div>

  <div class="me-3">
    <button mat-raised-button color="primary" class="me-1" [class.selectedButton]="tabIndex === 0" (click)="filterClosedMessages(false, 0)">
      {{ 'UI.OPEN' | translate }}
    </button>
    <button mat-raised-button color="primary" class="me-1" [class.selectedButton]="tabIndex === 1" (click)="filterClosedMessages(true, 1)">
      {{ 'UI.CLOSED' | translate }}
    </button>
  </div>

  <div class="me-3">
    <button mat-raised-button color="primary" class="me-1" [matMenuTriggerFor]="menu"><mat-icon>sort</mat-icon>{{ 'UI.SORT_BY' | translate }}</button>
    <mat-menu #menu="matMenu">
      <button *ngFor="let prop of allowedProperties | keyvalue" mat-menu-item (click)="onSelectedSortBy(prop.key)">
        {{ prop.value | translate }}
      </button>
    </mat-menu>
    <button mat-raised-button color="primary" (click)="onSortByDescendingToggle()">
      <ng-container *ngIf="collatePaginateQueryDto.sortByDescending">
        <mat-icon> keyboard_arrow_up </mat-icon> {{ 'UI.DESCENDING' | translate }}
      </ng-container>
      <ng-container *ngIf="!collatePaginateQueryDto.sortByDescending">
        <mat-icon> keyboard_arrow_down </mat-icon> {{ 'UI.ASCENDING' | translate }}
      </ng-container>
    </button>
  </div>
</div>

<section class="overflow-auto shadow" tabindex="0">
  <table *ngIf="ready" mat-table [dataSource]="dataSource" class="w-100 text-nowrap" matSort>
    <!-- Subject column -->
    <ng-container matColumnDef="subject">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.SUBJECT' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.subject }}</td>
    </ng-container>

    <!-- Created column -->
    <ng-container matColumnDef="createdAt">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.CREATED' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.createdAt | date: 'yyyy-MM-dd hh:mm' }}</td>
    </ng-container>

    <!-- Receiving country column -->
    <ng-container matColumnDef="receivingCountry">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.RECEIVING_COUNTRY' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ 'COUNTRY.' + row.receivingCountryCode | translate }}</td>
    </ng-container>

    <!-- Sent by column -->
    <ng-container matColumnDef="sentByGroup">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.SENT_BY' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.sentByGroup?.formattedName }}</td>
    </ng-container>

    <!-- Receiving group column -->
    <ng-container matColumnDef="receivingGroup">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.RECEIVING_GROUP' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.receivingGroup?.formattedName }}</td>
    </ng-container>

    <!-- Last response deadline -->
    <ng-container matColumnDef="lastResponseAt">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.GENERAL_CORRESPONDENCE.LAST_RESPONSE' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.lastResponseAt | date: 'yyyy-MM-dd hh:mm' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-element-row" (click)="onRowClicked(row)"></tr>
  </table>
  <mat-paginator
    [pageIndex]="collatePaginateQueryDto.pageIndex"
    [pageSize]="collatePaginateQueryDto.pageSize"
    [length]="getTotalItems"
    (page)="selectedPage($event)"
  ></mat-paginator>
</section>
